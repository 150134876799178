.home-info {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  margin: auto;
  padding: 2em 0em;
  font-size: 1em;
  line-height: 1.5em;
}

.home-info-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  padding: 4em 0em;
}

.home-info-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  padding: 0em 1.5em;
}

.home-info-col img {
  width: 5em;
  height: auto;
}

.home-info .title {
  font-weight: bold;
  font-size: 1.2em;
  padding: 0 16px;
}

@media screen and (max-width: 575px) {
  .home-info-row {
    flex-wrap: wrap;
  }
}
