.slide-container {
  width: 100%;
  margin: auto;
}

h3 {
  text-align: center;
}

.slide-container-single-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 700px;
  width: 100%;
  background-color: var(--color-dark);
  background-position: center center;
  background-repeat: no-repeat;
  margin: 4em 0em;
}
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 700px;
  background-color: var(--color-dark);
  background-position: center center;
  background-repeat: no-repeat;
}

.each-slide span {
  display: none;
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.home-info.add-container {
  height: 100%;
  min-height: calc(100vh - 18.7em);
  display: flex;
  flex-direction: column;
}

.home-info.add-container .add-title {
  font-size: 2.5em;
  margin: 2em 0em;
}

.home-info.add-container .add-description {
  padding-top: 2em;
}
